import { get, remove } from './index';
import { env, ENV_DEV, DEV_DEBUG } from '../config/env';

const DEBUG = (env === ENV_DEV) && DEV_DEBUG;

export const getAppInit = () => {
	const endpoint = `catalog/app_init`;
	if (!DEBUG) return get(endpoint);

	/* RANDOMIZE */
	return new Promise(resolve => {
		setTimeout(() => {
			resolve({
				id: 50,
				first_name: "John",
				last_name: "Doe",
				discovery_flags: ['must-fr', 'must-en', 'must-gb'],
				discovery_partitions: Array(40).fill(null).map((a, index) => ({
					label: `Partition ${index}`,
					value: index
				})),
		        account_executives: [
		          { id: 1, first_name: 'Foo', last_name: 'Bar' },
		          { id: 2, first_name: 'Toto', last_name: 'Titi' },
		          { id: 3, first_name: 'Tata', last_name: 'Tutu' },
				  { id: 4, first_name: 'sdf', last_name: 'sdfsg' },
				  { id: 5, first_name: 'dfhfu', last_name: 'dtue' },
				  { id: 6, first_name: 'xcv', last_name: 'li' },
				  { id: 7, first_name: 'op', last_name: 'pjkjg' },
				  { id: 8, first_name: 'qsz', last_name: 'kjhgjf' },
				  { id: 9, first_name: 'dtgueru', last_name: 'pjset' },
				],
				features: [
					'catalog/qualifications',
					'catalog/base',
					'catalog/logs',
					'catalog/update_status',
					'catalog/users_and_teams',
					'catalog/feature_groups',
					'catalog/features',
					'catalog/manage_features',
					'catalog/manage_groups',
					'catalog/banner_announcement',
				],
			})
		}, 500);
	});
}

export const logout = () => {
	const endpoint = `v1/users/logout`;
	if (!DEBUG) return remove(endpoint);

	console.log('[DELETE]', endpoint); //eslint-disable-line no-console
}
