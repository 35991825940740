	import queryString from 'jquery-param';

	import { conf } from '../config/env';
	import { getStore } from '../config/store';
	import { errorCatcher, openModaleDidYouKnow, closeModaleDidYouKnow } from '../utils/errors';

	export const get = async (endpoint, params = {}, catchErrors=null, defaultCodes=null) => await callApi({
		endpoint,
		params,
		catchErrors,
		defaultCodes,
		method: 'GET'
	});

	export const post = async (endpoint, params = {}, catchErrors = null, defaultCodes = null) => await callApi({
		endpoint,
		params,
		catchErrors,
		defaultCodes,
		method: 'POST'
	});

	export const put = async (endpoint, params = {}, catchErrors=null, defaultCodes=null) => await callApi({
		endpoint,
		params,
		catchErrors,
		defaultCodes,
		method: 'PUT'
	});

	/* Hell yeah, I cannot use "delete".... */
	export const remove = async (endpoint, params = {}, catchErrors=null, defaultCodes=null) => await callApi({
		endpoint,
		params,
		catchErrors,
		defaultCodes,
		method: 'DELETE'
	});

	export const getHeaders = (locale, userToken) => new Headers({
		'Accept': 'application/vnd.bandc.v1+json',
		'Accept-Language': locale,
		'Content-Type': 'application/json',
		'Authorization': `Token ${userToken}`,
	});

	const callApi = async datas => {
		const { endpoint, params, method, catchErrors, defaultCodes } = datas;
		const { env } = getStore();
		let api = `${conf.api}/${endpoint}`;

		const ob = Object.assign({}, {
			mode: 'cors',
			method,
				headers: getHeaders(env.locale, env.userToken),
			},
params.credentials === true ? { credentials: 'include' } : null,
		);


		if ((method === "GET" || method === "DELETE") && Object.keys(params).length > 0) {
			api = `${api}?${queryString(params)}`;
		}
		if (method === "POST" || method === "PUT") {
			ob.body = JSON.stringify(params);
		}

		// Making API Call
		let response;
		try {
			response = await fetch(api, ob);
		} catch (e) {
			// Gateway timeout - Server in charge
			if (response?.status === 504 ) {
				errorCatcher(e, {api, params:ob, code: response?.status});
				return { error: true, status: response?.status };
			}

			// Not Connected to Internet
			openModaleDidYouKnow();
			return new Promise(resolve => setTimeout(() => resolve(callApi(datas)), 7000));
		}

		closeModaleDidYouKnow();

		// Checking reponse statut
		try {
			if (response.status < 200 || response.status >= 300) {
				if (catchErrors && catchErrors.indexOf(response.status) > -1) {
					let payload;
					try {
						payload = await response.json();
					} catch (error) {
						payload = {};
					}
					return ({
						...payload,
						error: true,
						status: response.status
					});
				}
				else {
					// Trigger Error
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			}
			/* 204 Response */
			if (response.status === 204) return defaultCodes && defaultCodes['204'] ? defaultCodes['204'] : null;
		} catch (e) {
			errorCatcher(e, {api, params:ob, response, code: 'RESPONSE'});
			return;
		}

		// Parsing response
		try {
			const json = await response.json();
			return json;
		} catch (e) {
			errorCatcher(e, {api, params:ob, response, code: 'PARSE'});
			return;
		}

	}
