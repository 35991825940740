import React, { useState } from 'react';
import { TextField, Button, Container, Box, Switch, FormControlLabel } from '@mui/material';
import { postUserNotifications } from 'api/userNotifications';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    contactId: '',
    message: '',
    refresh: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    postUserNotifications({
      contactId: formData.contactId,
      message: formData.message,
      forceRefresh: formData.refresh,
    }).then((reponse) => console.log(reponse));
  };

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
        <TextField
          label="Contact Id"
          variant="outlined"
          name="contactId"
          value={formData.contactId}
          onChange={handleChange}
          required
        />
        <TextField
          label="Message"
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          rows={4}
          required
        />

        <FormControlLabel
          control={<Switch checked={formData.isActive} onChange={handleChange} name="refresh" />}
          label="Refresh app"
        />

        <Button type="submit" variant="contained" color="primary">
          Envoyer
        </Button>
      </Box>
    </Container>
  );
};

export default FormComponent;
