import { post } from 'api/index';


export const postUserNotifications = ({ contactId, message, forceRefresh = false }) => {
  const endpoint = `catalog/user_notifications`;
  return post(endpoint, {
    user_notification: {
      contact_id: contactId,  // The app user id that will receive the web socket notification
      message, // The message that will be displayed
      force_refresh: forceRefresh // Wether or not you want to force the refresh
    }
  });
};